import { useCallback, useEffect, useMemo, useState } from 'react';
import isNil from 'lodash/isNil';
import Pusher, { Channel } from 'pusher-js';

// Pusher configurations
const PUSHER_HOST = "stg-soketi.bdnai.trypencil.com" || '';
const PUSHER_KEY = "fKw1XqzU3253LKo" || 'app-key';
const PUSHER_CLUSTER = "BDNAI_STG" || 'APP_CLUSTER';
const PUSHER_CHANNEL_PREFIX = 'client_channel';
const PUSHER_LOCAL_STORAGE_KEY = 'pusher-subscribes';

export interface SoketiMessage<T> {
  content: T;
  status_code: number;
  error?: any;
}

export interface SoketiResponse<T> {
  eventName: string;
  message: SoketiMessage<T>;
}

export const usePusher = (clientId: number) => {
  const [pusherChannel, setPusherChannel] = useState<Channel>();

  // Initialize Pusher
  const pusher = useMemo(() => {
    return new Pusher(PUSHER_KEY, {
      wsHost: PUSHER_HOST,
      cluster: PUSHER_CLUSTER,
      disableStats: true,
      enabledTransports: ['ws'],
      forceTLS: true,
    });
  }, []);

  useEffect(
    function subscribeChannel() {
      if (!clientId || !pusher) {
        return;
      }

      const channelName = `${PUSHER_CHANNEL_PREFIX}_${clientId}`;
      if (pusherChannel?.name === channelName) {
        return;
      }
      const channel = pusher.subscribe(channelName);
      setPusherChannel(channel);

      localStorage.setItem(PUSHER_LOCAL_STORAGE_KEY, channelName);
      console.info(`Pusher - Subscribing "${channelName}" channel.`);
    },
    [clientId, pusher],
  );

  const unsubscribeChannel = useCallback(() => {
    const channelName = localStorage.getItem(PUSHER_LOCAL_STORAGE_KEY);
    if (channelName && pusher) {
      pusher.unsubscribe(channelName);
      localStorage.removeItem(PUSHER_LOCAL_STORAGE_KEY);
      console.info(`Pusher - Unsubscribing "${channelName}" channel.`);
    }
  }, [pusher]);

  useEffect(() => {
    function cleanup() {
      if (pusher) {
        unsubscribeChannel();
        pusher.disconnect();
      }
    }

    window.addEventListener('beforeunload', cleanup);

    return () => {
      window.removeEventListener('beforeunload', cleanup);
    };
  }, []);

  const bindEvent = useCallback(
    (eventName: string, callback: Function) => {
      if (isNil(pusherChannel)) return;

      pusherChannel.bind(eventName, callback);
      console.info(new Date().toTimeString(), '🚀 ~ Event binded:', eventName);
    },
    [pusherChannel],
  );

  const unbindEvent = useCallback(
    (eventName: string) => {
      if (isNil(pusherChannel)) return;

      pusherChannel.unbind(eventName);
      console.info(
        new Date().toTimeString(),
        '🚀 ~ Event unbinded:',
        eventName,
      );
    },
    [pusherChannel],
  );

  return { pusherChannel, bindEvent, unbindEvent, unsubscribeChannel };
};
