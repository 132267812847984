import { useEffect, useState } from 'react';

import { ListboxButton } from '@headlessui/react';
import { isNil } from 'lodash';
import { useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';

import { AngleDown, AngleUp } from '@assets/icons';
import { Button } from '@components/index';
import { useGetUser } from '@hooks/use-get-user';
import RoutesPath from '@routes/constants';
import { userProfile } from '@store/atoms/user';
import { LocalStorageKeys } from '@utils/auth';

import SelectWorkspaceDropdown from './SelectWorkspaceDropdown';
import { useListWorkspaces } from '../hooks/use-list-workspaces';
import { useSelectWorkspace } from '../hooks/use-select-workspace';
import { Workspace } from '../types';

export const SelectWorkspaceRefComponent = ({
  label,
  isOpen,
}: {
  label: string;
  isOpen: boolean;
}) => {
  return (
    <ListboxButton className="w-full cursor-pointer focus:outline-none">
      <div className="w-full h-12 flex justify-between items-center px-2 border border-gray-4 rounded-md focus:outline-none">
        <div className="flex justify-start items-center truncate font-semibold whitespace-nowrap">
          {!label && (
            <div className="text-gray-3 text-sm">Select workspace</div>
          )}
          {!isNil(label) && (
            <span className="truncate pl-2 text-sm">{label}</span>
          )}
        </div>
        {isOpen ? (
          <AngleUp className="text-gray-1" />
        ) : (
          <AngleDown className="text-gray-1" />
        )}
      </div>
    </ListboxButton>
  );
};

const SelectWorkspaceForm = () => {
  const navigate = useNavigate();

  const [currentUser, setCurrentUser] = useRecoilState(userProfile);

  const [workspaceSearchKeyword, setWorkspaceSearchKeyword] = useState<
    string | null
  >(null);
  const [activeParentWorkspaceId, setActiveParentWorkspaceId] = useState<
    number | null
  >(null);

  const [selectedWorkspace, setSelectedWorkspace] = useState<Workspace | null>(
    null,
  );

  const { data: userData } = useGetUser();

  const {
    workspaceList,
    isLoading: isLoadingWorkspaces,
    hasNextPage,
    fetchNextPage,
  } = useListWorkspaces({
    isActive: true,
    searchKeyword: workspaceSearchKeyword,
    parentClientId: activeParentWorkspaceId,
  });

  const { isLoading, refetch: refetchWorkspace } = useSelectWorkspace({
    userId: currentUser.id,
    clientUuid: selectedWorkspace?.uuid as string,
    enabled: false,
    onSuccess: () => {
      localStorage.setItem(LocalStorageKeys.IS_LOGGED_IN, 'true');
      navigate(RoutesPath.BRAND_LIBRARY, { replace: true });
    },
  });

  useEffect(() => {
    if (userData?.id) setCurrentUser(userData);
  }, [userData]);

  const onWorkspaceOptionSelect = (option: Workspace) => {
    setSelectedWorkspace(option);
  };

  const onWorkspaceSubmit = () => {
    refetchWorkspace();
  };

  return (
    <div className="w-full">
      <SelectWorkspaceDropdown
        options={workspaceList}
        isLoadingOptions={isLoadingWorkspaces}
        hasMoreOptions={hasNextPage}
        fetchMoreOptions={fetchNextPage}
        selectedOption={selectedWorkspace}
        onOptionSelect={onWorkspaceOptionSelect}
        setSearchKeyword={setWorkspaceSearchKeyword}
        activeParentId={activeParentWorkspaceId}
        setActiveParentId={setActiveParentWorkspaceId}
        PopperRefComponent={SelectWorkspaceRefComponent}
        classOverride="min-w-[256px] max-h-[215px]"
      />
      <div className="flex items-center mt-4">
        <Button
          variant="contained"
          label="Go to dashboard"
          isDisabled={isNil(selectedWorkspace) || isLoading}
          isLoading={isLoading}
          onClick={onWorkspaceSubmit}
        />
      </div>
    </div>
  );
};

export default SelectWorkspaceForm;
