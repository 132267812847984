import { ElementType, FC, useState } from 'react';

import { Popover, PopoverButton, PopoverPanel } from '@headlessui/react';
import { usePopper } from 'react-popper';

import { Document, EditPencil, Ellipsis, Locked, Trash } from '@assets/icons';

import { ToggleSwitch } from '..';

interface MoreOptionsProps {
  onClickDelete: () => void;
  onClickEdit?: () => void;
  onToggle?: () => void;
  isEditEnabled?: boolean;
  enabled?: boolean;
  showLock?: boolean;
  onClickPreview?: () => void;
}

interface PopoverOptionProps {
  Icon: ElementType;
  label: string;
  onOptionClick: () => void;
  isTogglePresent?: boolean;
  enabled?: boolean;
}

const PopoverOption: FC<PopoverOptionProps> = ({
  Icon,
  label,
  onOptionClick,
  isTogglePresent = false,
  enabled,
}) => {
  return (
    <div
      className="flex items-center space-x-2 w-full p-2 group cursor-pointer hover:bg-lightest"
      onClick={!isTogglePresent ? onOptionClick : () => {}}
    >
      <Icon className="text-dark-1 h-4 w-4" />
      <div className="text-dark-1 text-sm">{label}</div>
      <div className="flex justify-end pl-10">
        {isTogglePresent && (
          <ToggleSwitch
            isChecked={enabled as boolean}
            onChange={onOptionClick}
          />
        )}
      </div>
    </div>
  );
};

const MoreOptions: FC<MoreOptionsProps> = ({
  onClickDelete,
  onClickEdit = () => {},
  onToggle = () => {},
  isEditEnabled = true,
  showLock = false,
  enabled = false,
  onClickPreview,
}) => {
  const [referenceElement, setReferenceElement] =
    useState<HTMLButtonElement | null>();
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>();
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: 'bottom-end',
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [0, 8],
        },
      },
    ],
  });

  return (
    <Popover>
      {() => (
        <>
          <PopoverButton
            ref={
              setReferenceElement as (
                instance: HTMLButtonElement | null,
              ) => void
            }
            as="div"
            className="flex justify-center items-center w-10 h-10 rounded cursor-pointer focus:outline-none"
          >
            <Ellipsis />
          </PopoverButton>
          <PopoverPanel
            className="!w-40 py-2 bg-white rounded-md shadow-2xl z-10"
            ref={element => setPopperElement(element as HTMLDivElement | null)}
            style={styles.popper}
            {...attributes.popper}
          >
            {showLock && (
              <PopoverOption
                Icon={Locked}
                label="Lock"
                enabled={enabled}
                isTogglePresent={true}
                onOptionClick={onToggle}
              />
            )}
            {isEditEnabled && (
              <PopoverOption
                Icon={EditPencil}
                label="Edit"
                onOptionClick={onClickEdit}
              />
            )}
            {onClickPreview && (
              <PopoverOption
                Icon={Document}
                label="Preview"
                onOptionClick={onClickPreview}
              />
            )}
            <PopoverOption
              Icon={Trash}
              label="Delete"
              onOptionClick={onClickDelete}
            />
          </PopoverPanel>
        </>
      )}
    </Popover>
  );
};

export default MoreOptions;
