import { useMemo } from 'react';

import { isEmpty, isNil, pick } from 'lodash';
import { useInfiniteQuery } from 'react-query';

import { createHttpClient } from '@api/http-client';
import { END_POINTS } from '@constants/api-endpoints';
import { QUERY_KEYS } from '@constants/query-keys';

import { ListWorkspaceResponse, Workspace } from '../types';

interface UseListWorkspacesProps {
  limit?: number;
  isActive?: boolean;
  searchKeyword?: string | null;
  parentClientId?: number | null;
  isEnabled?: boolean;
  onSuccess?: (data: any) => void;
}

export const useListWorkspaces = ({
  limit = 10,
  isActive,
  searchKeyword,
  parentClientId,
  isEnabled = true,
  onSuccess,
}: UseListWorkspacesProps = {}) => {
  const client = createHttpClient();
  const queryKey = isNil(isActive)
    ? [
        QUERY_KEYS.WORKSPACE.SELECT_WORKSPACE_LIST,
        QUERY_KEYS.WORKSPACE.ALL_WORKSPACES,
      ]
    : [
        QUERY_KEYS.WORKSPACE.SELECT_WORKSPACE_LIST,
        isActive,
        searchKeyword,
        parentClientId,
      ];

  const query = useInfiniteQuery(
    queryKey,
    ({ pageParam = 'firstPage' }) => {
      const pagination =
        pageParam === 'firstPage'
          ? {
              limit,
              ...(!isEmpty(searchKeyword) && {
                nameSearchKeyword: searchKeyword,
              }),
              ...(isActive && { isActive }),
              ...(parentClientId && { parentClientId }),
            }
          : { next: pageParam };

      return client<ListWorkspaceResponse>({
        url: END_POINTS.WORKSPACE.LIST_WORKSPACE,
        params: pagination,
      });
    },
    {
      retry: 0,
      enabled: isEnabled,
      getNextPageParam: lastPage =>
        lastPage.cursor?.hasNext ? lastPage.cursor?.next : undefined,
      onSuccess,
    },
  );

  const { data } = query;

  const workspaceList = useMemo(() => {
    if (!data?.pages) {
      return [];
    }

    return data?.pages.reduce((workspaces: Workspace[], page) => {
      if (!isEmpty(page.items)) {
        workspaces.push(
          ...page.items.map(item => {
            const workspaceItem: any = pick(item, [
              'id',
              'parent_id',
              'client_path',
              'uuid',
              'brand_url',
            ]);

            return {
              ...workspaceItem,
              name: item?.name || '',
            };
          }),
        );
      }

      return workspaces;
    }, []);
  }, [data]);

  return { ...query, queryKey, workspaceList };
};
