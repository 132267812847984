import { useState } from 'react';

import { isNil } from 'lodash';

import { AngleDown, AngleUp } from '@assets/icons';
import ProgressBar from '@components/progress-bar/ProgressBar';
import { useUploadProgress } from '@hooks/use-upload-progress';

const UploadProgress = () => {
  const { items } = useUploadProgress();

  const [isOpen, setIsOpen] = useState(true);

  const onHideButtonClick = () => {
    setIsOpen(!isOpen);
  };

  const AngleIcon = isOpen ? AngleDown : AngleUp;

  if (items.length === 0) {
    return null;
  }

  return (
    <div className="absolute bottom-0 right-0 p-5 mb-14 z-[100]">
      <div className="flex flex-col bg-white shadow-md rounded-md w-64 max-h-[400px]">
        <div className="flex justify-between items-center px-5 py-3">
          <div className="font-semibold">
            Uploading {items.length} item{items.length > 1 && 's'}
          </div>
          <AngleIcon
            className="text-gray-3 cursor-pointer"
            onClick={onHideButtonClick}
          />
        </div>
        {isOpen && (
          <div className="flex-grow overflow-y-auto">
            {items.map(item => (
              <div key={item.name} className="border-t border-gray-2">
                <div className="flex justify-between items-center px-5 py-3">
                  <div className="space-y-2 w-full">
                    <p className="min-w-0 break-words">{item.name}</p>
                    {!isNil(item.progressPercentage) && (
                      <>
                        <ProgressBar progress={item.progressPercentage} />
                        <p className="text-gray-3 text-sm">
                          {item.progressPercentage === 100
                            ? 'Done!'
                            : `${item.progressPercentage}%`}
                        </p>
                      </>
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default UploadProgress;
