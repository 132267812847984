import { Fragment } from 'react';

import {
  Listbox,
  ListboxOption,
  ListboxOptions,
  Transition,
} from '@headlessui/react';
import { isEmpty } from 'lodash';

import { Check } from '@assets/icons';

import { Popper, Spinner } from '..';

export interface SelectProps<T> {
  classOverride?: string;
  selectedOption?: T;
  popperPlacement?: 'bottom-start' | 'bottom-end' | 'right-start';
  PopperRefComponent: ({
    label,
    isOpen,
    placeHolder,
  }: {
    label: string;
    isOpen: boolean;
    placeHolder?: string;
  }) => JSX.Element;
  options: T[];
  isLoading?: boolean;
  onOptionSelect: (option: T) => void;
  optionLabel?: keyof T;
  optionKey?: keyof T;
  placeHolder?: string;
}

const Select = <T,>({
  options,
  selectedOption,
  onOptionSelect,
  popperPlacement = 'bottom-end',
  PopperRefComponent,
  classOverride = '',
  isLoading = false,
  optionLabel = 'label' as keyof T,
  optionKey = 'id' as keyof T,
  placeHolder = 'Select',
}: SelectProps<T>) => {
  return (
    <Listbox value={selectedOption ?? null} onChange={onOptionSelect}>
      {({ open }) => (
        <Popper
          placement={popperPlacement}
          PopComponent={({ popperElRef, setPopperElement, width }: any) => (
            <Transition
              afterLeave={() => {
                setPopperElement(null);
              }}
              as={Fragment}
              beforeEnter={() => setPopperElement(popperElRef.current)}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
              show={open}
            >
              <ListboxOptions
                className={`flex flex-col border border-gray-2 bg-white rounded shadow-lg ${classOverride}`}
                static
                style={{ width }}
              >
                {isLoading && (
                  <div className="p-6">
                    <Spinner />
                  </div>
                )}
                {!isLoading && isEmpty(options) && (
                  <div className="flex justify-center p-6 text-sm text-gray-1">
                    No data found
                  </div>
                )}
                {!isLoading && !isEmpty(options) && (
                  <span className="flex-grow overflow-auto">
                    {options.map(option => {
                      const isSelected =
                        option[optionKey] === selectedOption?.[optionKey];

                      return (
                        <ListboxOption
                          key={String(option[optionLabel])}
                          value={option}
                        >
                          <span className="flex items-center justify-between cursor-pointer focus:select-none pl-4 h-11 border-b border-gray-2 hover:bg-lightest pr-2">
                            <span className="text-sm block truncate flex-grow">
                              {String(option[optionLabel])}
                            </span>
                            <div className="flex items-center">
                              <Check
                                className={`flex-none text-primary ${isSelected ? '' : 'invisible'}`}
                              />
                            </div>
                          </span>
                        </ListboxOption>
                      );
                    })}
                  </span>
                )}
              </ListboxOptions>
            </Transition>
          )}
          RefComponent={() => (
            <PopperRefComponent
              label={selectedOption ? String(selectedOption[optionLabel]) : ''}
              isOpen={open}
              placeHolder={placeHolder}
            />
          )}
        />
      )}
    </Listbox>
  );
};

export default Select;
